import { AxiosResponse } from 'axios';
import ConfirmModal from 'components/Modal/confirm';
import { parseCookies, setCookie } from 'nookies';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SubscriptionService } from 'services/subscription';
import { useFlow } from '../../contexts/FlowContext';
import { isBankSlip, isCreditCard, isPix } from '../../helpers/paymentMethod';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { api } from '../../services/api';
import { convertCentsToBRL, onlyNumbers } from '../../utils/currency';
import { handleHTTPErrorMessage } from '../../utils/handleHTTPErrorMessage';
import { queryString } from '../../utils/queryString';
import { convertParamsToObject, getParameterByName } from '../../utils/urlParams';
import {
	HasActiveSubscriptionsError,
	HasCanceledSubscriptionsError,
	UnknownSubscriptionError,
} from './errors/subscription';
import { InstallmentsProps, OfferProps, OfferTypeOfCharge, OneClickProviderProps, PaymentMethods } from './types';
import { BumpsProps } from './types/Bumps';
import { CardProps } from './types/Cards';
import { CustomerProps, PaymentRequestProps, PaymentResponseProps, TransactionProps } from './types/Payment';
import { ProductProps } from './types/Product';
import { UpsellProps } from './types/Upsell';
import { ValidateSubscriptionErrorProps } from './types/subscription';

export interface OneClickContextProps {
	useQuery: () => any;
	oneClickFormRef: any;
	isLoading: boolean;
	isSending: boolean;
	isCalculating: boolean;

	isModalOpen: boolean;
	setIsModalOpen: (isModalOpen: boolean) => void;

	error: any;
	paymentError: string;
	setPaymentError: (error: string) => void;

	resetErrors: () => void;

	fetch: (code: string) => Promise<void>;
	track: (code: string) => Promise<void>;
	pay: () => Promise<void>;
	decline: () => Promise<void>;

	fromTransaction: PaymentResponseProps;
	fromPaymentMethod: PaymentMethods;

	offer: OfferProps | null;
	setOffer: (offer: OfferProps) => void;

	currency: string;
	country: CountryOption;

	product: ProductProps;
	affiliates: any;
	bumps: BumpsProps[];

	installments: InstallmentsProps;
	minPrice: number;
	selectedInstallment: { label: string; value: number; quantity: number };

	totalAmountWithInterest: number;
	setTotalAmountWithInterest: (value: number) => void;

	items: string[];
	setItems: (items: string[]) => void;

	cards: CardProps[];
	selectedCard: CardProps | null;
	setSelectedCard: (card: CardProps) => void;

	itemsAmount: number;
	calculateItemsAmount: (items: string[]) => Promise<void>;

	changeInstallmentOption: (value: number) => void;

	previousOrder: PaymentResponseProps;
	couponValue: number | null;
	paymentResponse: PaymentResponseProps;
	actualTransaction: TransactionProps;

	isWaitingPixPayment: boolean;
	redirect: (payment: PaymentResponseProps) => void;

	handleAceptDiscount: () => void;
}

export interface CountryOption {
	label: string;
	value: string;
	metadata: {
		iso_code: string;
		name: string;
		languages: string[];
		currency: string;
		dial_code: string;
		region: string;
		flag: {
			src: string;
		};
	};
}

const OneClickContext = createContext({} as OneClickContextProps);

export const OneClickProvider = ({ children }: OneClickProviderProps) => {
	const { t, i18n } = useTranslation('upsell');
	const {
		setPurchases: setFlowPurchases,
		upsellDiscount,
		setUpsellDiscount,
		setIsUpsellDiscountModalOpen,
		isDiscountAccepted,
		flow,
		step,
		setIsDiscountAccepted,
	} = useFlow();
	const oneClickFormRef = useRef(null);
	const isProduction = process.env.REACT_APP_MIDAS_ENVIRONMENT === 'production';
	const minutesToSetSessionValid = 60;

	const [isLoading, setIsLoading] = useState(true);
	const [isSending, setIsSending] = useState(false);
	const [isCalculating, setIsCalculating] = useState(false);
	const [isValidSession, setIsValidSession] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
	const [modalPromise, setModalPromise] = useState(null);
	const [confirmModalContent, setConfirmModalContent] = useState<{
		message: string;
		description: string;
		content: React.ReactNode;
		confirmButtonText?: string;
		cancelButtonText?: string;
	}>(null);

	const [error, setError] = useState(null);
	const [paymentError, setPaymentError] = useState(null);

	const [previousOrder, setPreviousOrder] = useState<PaymentResponseProps>(null);
	const [paymentResponse, setPaymentResponse] = useState<PaymentResponseProps>(null);
	const [fromPaymentMethod, setFromPaymentMethod] = useState<PaymentMethods>(null);
	const [fromTransaction, setFromTransaction] = useState<PaymentResponseProps>(null);
	const [isForeignCustomer, setIsForeignCustomer] = useState<boolean>(false);

	const [offer, setOffer] = useState<OfferProps>(null);
	const [country, setCountry] = useState<CountryOption>(null);
	const [currency, setCurrency] = useState<string>(null);
	const [product, setProduct] = useState(null);
	const [gateway, setGateway] = useState('pagseguro');

	const [couponValue, setCouponValue] = useState<number | null>(null);
	const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

	const [affiliates, setAffiliates] = useState([]);
	const [customer, setCustomer] = useLocalStorage<CustomerProps>('ticto@flow@customer', null);
	const [purchases, setPurchases] = useState<string[]>([]);

	const [cards, setCards] = useState<CardProps[]>([]);
	const [selectedCard, setSelectedCard] = useState<CardProps>(null);

	const [bumps, setBumps] = useState(null);
	const [items, setItems] = useState([]);

	const [upsell, setUpsell] = useState<UpsellProps>(null);

	const [installments, setInstallments] = useState(null);
	const [selectedInstallment, setSelectedInstallment] = useState(null);

	const [totalAmountWithInterest, setTotalAmountWithInterest] = useState(0);

	const [itemsAmount, setItemsAmount] = useState(0);

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethods>(PaymentMethods.CARD);
	const minPrice = 500;

	const [isWaitingPixPayment, setIsWaitingPixPayment] = useState(false);
	const [actualTransaction, setActualTransaction] = useState<TransactionProps>(null);

	const handleConfirm = response => {
		setIsConfirmModalVisible(false);
		if (modalPromise) {
			modalPromise(response); // Resolve a promessa com o valor true ou false
		}
	};

	const showConfirmModal = (
		message: string,
		description?: string,
		content?: React.ReactNode,
		confirmButtonText?: string,
		cancelButtonText?: string
	) => {
		setConfirmModalContent({ message, description, content, confirmButtonText, cancelButtonText });
		return new Promise(resolve => {
			setModalPromise(() => resolve);
			setIsConfirmModalVisible(true);
		});
	};
	const useQuery = () => {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	};

	const getHost = () => {
		return getParameterByName('mainDomain')
			? window.location.protocol + '//' + getParameterByName('mainDomain')
			: process.env.REACT_APP_CHECKOUT_URL;
	};

	const resetErrors = () => {
		setError(null);
		setPaymentError(null);
	};

	const reset = () => {
		resetErrors();
		setBumps(null);
		setInstallments(null);
		setSelectedInstallment(null);
		setItemsAmount(0);
		setTotalAmountWithInterest(0);
		setItems([]);
	};

	const getHash = () => {
		const fromURL = getParameterByName('hash');
		const hash = fromURL;

		return hash;
	};

	const genericError = () => {
		setError(String(t('error.generic')));
		setIsLoading(false);
		setIsCalculating(false);
	};

	const validateSession = async (order: PaymentResponseProps) => {
		if (!isProduction) {
			setIsValidSession(true);
			return;
		}

		const hour = String(order.created_at).split(' ')[1];
		const date = String(order.created_at).substring(0, 10).split('/').reverse().join('-') + ' ' + hour;

		const createdAt = new Date(date);
		const now = new Date();

		const diff = Math.abs(now.getTime() - createdAt.getTime());
		const diffMinutes = Math.floor(diff / (1000 * 60));

		setIsValidSession(diffMinutes < minutesToSetSessionValid);
	};

	const redirectToOffer = (offerCode, urlRedirect) => {
		if (offerCode) {
			window.location.href = `${urlRedirect}${offerCode}`;
		}
	};

	const fetch = async (code: string) => {
		setIsLoading(true);
		reset();

		const hash = getHash();

		if (!hash) {
			const urlRedirect = process.env.REACT_APP_CHECKOUT_URL;

			let upButton = document.querySelector('.ticto-upsell-button');

			if (upButton) {
				// Tenta buscar o atributo 'data-fallback-offer'
				let dataFallbackOffer = upButton.getAttribute('data-fallback-offer');

				// Tenta buscar o atributo 'data-offer-code'
				let dataFallbackOfferOneClick = upButton.getAttribute('data-offer-code');

				// Redireciona com base no atributo encontrado
				redirectToOffer(dataFallbackOffer || dataFallbackOfferOneClick, urlRedirect);
			}

			genericError();
			return;
		}

		await Promise.all([
			api
				.get<PaymentResponseProps>(`order/${hash}`)
				.then(response => {
					const order = response.data;
					const currency = order.currency?.iso_code ?? 'BRL';

					setFromPaymentMethod(order.transactions[0].payment_method);
					// setSelectedPaymentMethod(order.transactions[0].payment_method)
					setFromTransaction(order);
					setCustomer(order.customer);
					setPurchases(order.customer.purchases);
					setFlowPurchases(order.customer.purchases);
					setCurrency(currency);

					const formattedCountry = {
						label: order.customer.country?.name ?? 'Brazil',
						value: order.customer.country?.iso_code ?? 'BR',
						metadata: {
							iso_code: order.customer.country?.iso_code ?? 'BR',
							name: order.customer.country?.name ?? 'Brazil',
							languages: order.customer.country?.languages ?? ['Portuguese'],
							currency: order.customer.country?.currency ?? 'BRL',
							dial_code: order.customer.country?.dial_code ?? '+55',
							region: order.customer.country?.region ?? 'Americas',
							flag: {
								src: order.customer.country?.flag.src ?? 'countries_flags/br.svg',
							},
						},
					};

					setCurrency(currency);
					setCountry(formattedCountry);

					console.log('FromOffer', order.main_offer);
					console.log('ORDER MIDAS', order);

					setCookie(undefined, 'ticto@flow@client', JSON.stringify(order.customer), {
						path: '/',
						partitioned: true,
					});
					setCookie(undefined, 'customer@purchases', JSON.stringify(purchases), {
						path: '/',
						partitioned: true,
					});

					localStorage.setItem('ticto@flow@customer', JSON.stringify(order.customer));

					setIsForeignCustomer(order.customer.is_foreign);

					validateSession(order);

					setGateway(order.transactions[0].gateway);

					if (isCreditCard(order.transactions[0].payment_method)) {
						let customerCards = order.transactions.map(transaction => {
							return transaction.card;
						});
						setCards(customerCards);
						setSelectedCard(customerCards[0]);
					}

					api.get(`offer/${code}`)
						.then(async response => {
							setProduct(response.data.product);
							let returnedOffer = response.data;
							returnedOffer.original_price = returnedOffer.price;
							returnedOffer.price =
								returnedOffer?.subscription?.first_charge_price ?? returnedOffer.price;

							delete returnedOffer.bumps;
							delete returnedOffer.elements;
							delete returnedOffer.plugins;
							delete returnedOffer.theme_settings;
							delete returnedOffer.product;

							setOffer(returnedOffer);
							setItemsAmount(returnedOffer.price);

							await getInstallments(response.data, returnedOffer.price);

							if (response.data?.builder) {
								let { bumps, upsell: up } = response.data?.builder;
								bumps && setBumps(bumps);
								up && setUpsell(up);
							}
						})
						.catch(error => {
							setError(handleHTTPErrorMessage(error));
						})
						.finally(() => setIsLoading(false));
				})
				.catch(error => {
					genericError();
					return;
				})
				.finally(() => setIsLoading(false)),
		]);
	};

	const track = async (code: string) => {
		setIsLoading(true);
		await Promise.all([
			api
				.get(`t/${code}${window.location.search}`)
				.then(async response => {
					setAffiliates(response.data);
				})
				.catch(error => {
					setError(handleHTTPErrorMessage(error));
				})
				.finally(() => setIsLoading(false)),
		]);
	};

	const validateCustomerSubscriptions = async (
		customerData: {
			email: string;
			document: string;
			name: string;
		},
		offer_codes: string[]
	): Promise<boolean> => {
		let customer: {
			email: string;
			cpf?: string;
			cnpj?: string;
		} = {
			email: customerData?.email,
		};

		if (onlyNumbers(customerData.document).length == 11) {
			customer.cpf = onlyNumbers(customerData.document);
		} else {
			customer.cnpj = onlyNumbers(customerData.document);
		}

		try {
			await SubscriptionService.validate(offer_codes, customer);
		} catch (error: any) {
			const { data } = error.response as AxiosResponse<ValidateSubscriptionErrorProps>;

			const { type, offers } = data;

			switch (type) {
				case 'active-subscriptions':
					var offerNames = offers.map(offer => offer.name).join(', ');

					var message =
						offers.length > 1
							? t('validateSubscription.active.title.multi')
							: t('validateSubscription.active.title.single');

					throw new HasActiveSubscriptionsError(`${message} ${offerNames}`);

				case 'canceled-subscriptions':
					var offerNames = offers.map(offer => offer.name).join(', ');

					var message =
						offers.length > 1
							? t('validateSubscription.canceled.title.multi')
							: t('validateSubscription.canceled.title.single');

					var description =
						offers.length > 1
							? t('validateSubscription.canceled.description.multi')
							: t('validateSubscription.canceled.description.single');

					var content = (
						<div>
							{offers.map(offer => (
								<p>
									{offer.name} - {convertCentsToBRL(offer.price)}
								</p>
							))}
						</div>
					);

					var result = await showConfirmModal(
						message,
						description,
						content,
						t('validateSubscription.canceled.warning.continue'),
						t('validateSubscription.canceled.warning.cancel')
					);

					if (result) {
						return;
					}

					throw new HasCanceledSubscriptionsError(t('validateSubscription.canceled.error'));

				default:
					throw new UnknownSubscriptionError(t('validateSubscription.defaultError'));
			}
		}
	};

	const normatizePaymentData = async (): Promise<PaymentRequestProps> => {
		const hash = getHash();

		let data = {
			one_click_buy_offer_code: offer.code,
			paid_amount: totalAmountWithInterest,
			order_items: [],
			has_two_cards: false,
			hash,
			origin: 'upsell',
			gateway,
			payment_method: fromPaymentMethod,
			installments: isCreditCard(fromPaymentMethod) ? selectedInstallment.quantity : 1,
		};

		if (isCreditCard(fromPaymentMethod)) {
			data['installments_labels'] = selectedInstallment;
			data['code'] = selectedCard?.code;
		}

		if (upsellDiscount) {
			data['coupon'] = upsellDiscount?.coupon.name;
		}

		let order_items = [];
		if (bumps && bumps.length > 0) {
			order_items = bumps
				.filter(bump => bump.offers.find(offer => items.includes(offer.code)))
				.map(bump => bump.offers)
				.flat()
				.filter(offer => items.includes(offer.code))
				.map(item => {
					return {
						type: 'bump',
						offer_code: item.code,
						quantity: 1,
					};
				});
		}

		order_items.push({
			offer_code: offer.code,
			type: 'main',
			quantity: 1,
		});

		data.order_items = order_items;

		if (offer.type_of_charge === OfferTypeOfCharge.RECURRING) {
			await validateCustomerSubscriptions(
				{
					document: customer.cpf,
					email: customer.email,
					name: customer.name,
				},
				order_items.map(item => item.offer_code)
			);
		}

		return data;
	};

	const pay = async () => {
		let data;

		try {
			data = await normatizePaymentData();
		} catch (error) {
			if (
				['HasActiveSubscriptionsError', 'HasCanceledSubscriptionsError', 'UnknownSubscriptionError'].includes(
					error.name
				)
			) {
				setPaymentError(error.message);
				return;
			}
		}

		if (!data) {
			genericError();
			return;
		}

		setIsSending(true);
		setPaymentError(null);
		isValidSession ? await oneClickBuy(data) : goToPayment(data);
	};

	const oneClickBuy = async (data: PaymentRequestProps) => {
		await Promise.all([
			api
				.post(`payment/one-click-buy${window.location.search}`, data)
				.then(async response => {
					if (!!response.data.transactions) {
						setActualTransaction(response.data.transactions[0]);
					} else {
						throw new Error();
					}

					setPaymentResponse(response.data);

					if (isPix(response.data.transactions[0].payment_method)) {
						setIsWaitingPixPayment(true);
					} else {
						redirect(response.data);
					}
				})
				.catch(error => {
					setPaymentError(handleHTTPErrorMessage(error));
				})
				.finally(() => setIsSending(false)),
		]);
	};

	const goToPayment = (data: PaymentRequestProps) => {
		const offerCode = offer.code ?? data.one_click_buy_offer_code;
		const upsellURL = `${getHost()}/${offerCode}`;

		window.parent.location.href = upsellURL;
	};
	const handleAceptDiscount = async () => {
		setIsDiscountAccepted(true);
		setIsUpsellDiscountModalOpen(false);
		setIsLoading(false);
		setIsSending(false);
		setIsModalOpen(true);
		await fetch(flow ? step?.code : upsellDiscount.offer_code);
	};

	const decline = async (declineFromOutsideModal = null) => {
		const reject = document.querySelectorAll('.ticto-refuse-button');
		const accept = document.getElementsByClassName('ticto-upsell-button');
		let offerCode = accept[0]?.getAttribute('data-offer-code');

		let olderButtonText: string[] = [];
		for (let i = 0; i < reject.length; i++) {
			olderButtonText.push(reject[i].innerHTML);
			reject[i].innerHTML = 'Por favor, aguarde...';
		}

		setIsSending(true);

		const params = String(window.location.search).replace('?', '');
		let downsellURL: string = `${getHost()}/thanks/${getHash()}?withoutFrame=true&${params}`;

		const isWithoutMain =
			params.includes('withoutMain') || offerCode === fromTransaction?.main_offer?.builder?.upsell?.offer_code;

		// é um upsell de recusa de segundo nível? (upsell do upsell ou downsell do upsell)
		if (isWithoutMain) {
			if (!offerCode) {
				console.log('TICTO -> Não foi possível encontrar a oferta original deste upsell.');
				console.log('TICTO -> Não há sessão de compra ativa.');
				// Retornando o texto do botão para o padrão anterior.
				for (let i = 0; i < reject.length; i++) {
					reject[i].innerHTML = olderButtonText[i];
				}
				return;
			}

			try {
				const response = await api.get<OfferProps>(`offer/${offerCode}`);

				const declineUpsell = response.data?.builder?.upsell;

				if (declineUpsell?.discount?.coupon && !isDiscountAccepted) {
					setUpsellDiscount({ ...declineUpsell.discount, offer_code: declineUpsell.offer_code });
					setIsUpsellDiscountModalOpen(true);
					return;
				}

				if (declineUpsell?.on_reject_url) {
					downsellURL = declineUpsell.on_reject_url + '?withoutMain=true&' + params;
				} else {
					if (response.data?.builder?.default_thankyou_url)
						downsellURL = response.data?.builder?.default_thankyou_url;
				}
			} catch (error) {
				console.log('Erro ao buscar dados de recusa do upsell');
				return;
			}
		} else {
			// é uma rejeição via botão de fora do modal?
			if (declineFromOutsideModal) {
				const orderHash = getHash();

				if (!orderHash) {
					console.log('TICTO -> Não foi possível encontrar a oferta original deste upsell.');
					console.log('TICTO -> Não há sessão de compra ativa.');
					const urlRedirect = process.env.REACT_APP_CHECKOUT_URL;

					let downButton = document.querySelector('.ticto-refuse-button');

					if (downButton) {
						// Tenta buscar o atributo 'data-fallback-offer'
						let dataFallbackOffer = downButton.getAttribute('data-fallback-offer');

						// Tenta buscar o atributo 'data-offer-code'
						let dataFallbackOfferOneClick = downButton.getAttribute('data-offer-code');

						// Redireciona com base no atributo encontrado
						redirectToOffer(dataFallbackOffer || dataFallbackOfferOneClick, urlRedirect);
					}
					// Retornando o texto do botão para o padrão anterior.
					for (let i = 0; i < reject.length; i++) {
						reject[i].innerHTML = olderButtonText[i];
					}
					return;
				}

				try {
					const response = await api.get<PaymentResponseProps>(`order/${orderHash}`);
					const declineUpsell = response.data?.main_offer?.builder?.upsell;

					if (declineUpsell?.discount?.coupon && !isDiscountAccepted) {
						setUpsellDiscount({ ...declineUpsell.discount, offer_code: declineUpsell.offer_code });
						setIsUpsellDiscountModalOpen(true);
						return;
					}

					if (declineUpsell?.on_reject_url)
						downsellURL = declineUpsell.on_reject_url + '?withoutMain=true&' + params;
				} catch (error) {
					console.log('Erro ao buscar dados de recusa do upsell via botão de fora do modal');
					return;
				}
			} else {
				const onReject = fromTransaction?.main_offer?.builder?.upsell?.on_reject_url;
				// é um clique no botão "Não quero, obrigado." do modal?
				if (onReject) downsellURL = onReject + '?withoutMain=true&' + params;
			}
		}

		// Retornando o texto do botão para o padrão anterior.
		for (let i = 0; i < reject.length; i++) {
			reject[i].innerHTML = olderButtonText[i];
		}

		downsellURL.includes('withoutFrame')
			? (window.parent.location.href = downsellURL)
			: (window.location.href = downsellURL);

		setIsModalOpen(false);
		setIsLoading(true);
		setIsSending(false);
		return;
	};

	const redirect = (payment: PaymentResponseProps) => {
		let params = convertParamsToObject(window.location.search);
		params = { ...params, offerCode: offer.code };

		const upsellURL = `${getHost()}/thanks/${payment?.hash}${queryString(params)}`;

		window.parent.location.href = upsellURL;
	};

	const calculateItemsAmount = async (_items: string[]) => {
		let amount = offer.price;
		const bumpsToCalculate = bumps
			.filter(bump => bump.offers.find(offer => _items.includes(offer.code)))
			.map(bump => bump.offers)
			.flat()
			.filter(offer => _items.includes(offer.code));

		bumpsToCalculate.map(item => {
			amount += Number(item.price);
		});

		setItemsAmount(amount);

		await getInstallments(offer, amount);
	};

	const getInstallments = async (offer: OfferProps, price: number) => {
		setIsCalculating(true);
		if (offer && price) {
			let installmentsToMap = offer.credit_card_installments_options;

			price -= couponValue;

			const isForeign =
				(isForeignCustomer && customer?.is_foreign) ||
				(country?.value !== 'BR' && country !== null) ||
				(currency !== 'BRL' && currency !== null);

			if (isForeign) installmentsToMap = [1];

			let availableInstallments = installmentsToMap
				.map(qtd => {
					const installment = price / qtd;
					if (installment >= minPrice) return qtd;
				})
				.filter(installments => installments)
				.sort((a, b) => b - a)[0];

			if (!isCreditCard(fromPaymentMethod ?? selectedPaymentMethod)) availableInstallments = 1;

			await Promise.all([
				api
					.get(
						`calculate/interest/offer/${offer.code}/amount/${price}/installments/${
							availableInstallments ?? 1
						}/payment-method/${fromPaymentMethod ?? selectedPaymentMethod}`
					)
					.then(response => {
						let newInstallments = { ...response.data };
						newInstallments.labels = newInstallments.installment_values
							.map((installment, index) => {
								const label = {
									label: `${[index + 1]}${String(
										t('installmentsLabels.timesOf')
									)} ${convertCentsToBRL(installment[index + 1], currency)}`,
									value: installment[index + 1],
									quantity: index + 1,
								};
								return label;
							})
							.filter(installment =>
								offer.credit_card_installments_options.includes(installment.quantity)
							)
							.reverse();

						setInstallments(newInstallments);

						const availableSelectedInstallment =
							selectedInstallment && selectedInstallment.quantity
								? newInstallments.labels.find(inst => inst.quantity === selectedInstallment.quantity)
								: null;

						const newSelectedInstallment = availableSelectedInstallment ?? newInstallments.labels.at(0);

						setSelectedInstallment(newSelectedInstallment);
					})
					.catch(error => {
						setError(handleHTTPErrorMessage(error));
					})
					.finally(() =>
						setTimeout(() => {
							setIsCalculating(false);
						}, 200)
					),
			]);
		}
	};

	const changeInstallmentOption = (value: number) => {
		setSelectedInstallment(installments.labels.find(label => label.value == value));
	};

	const applyCoupon = useCallback(
		async (offerCode: string) => {
			if (isApplyingCoupon || !upsellDiscount) return;
			setIsApplyingCoupon(true);

			try {
				const { data } = await api.get<{ discount: number }>(
					`calculate/discount/upsell/offer/${offerCode}/coupon/${upsellDiscount.coupon.name}`
				);
				setCouponValue(data.discount);
			} catch (error) {
				console.log(error);
			} finally {
				setIsApplyingCoupon(false);
			}
		},
		[upsellDiscount]
	);

	useEffect(() => {
		isLoading && setError(null);
		if (offer) setTotalAmountWithInterest(offer.price - couponValue);
	}, [offer, isLoading]);

	useEffect(() => {
		let totalValue = 0;
		if (offer) {
			if (isCreditCard(fromPaymentMethod) && selectedInstallment) {
				totalValue = selectedInstallment.value * selectedInstallment.quantity;
			} else {
				itemsAmount > offer.price ? (totalValue = itemsAmount) : (totalValue = offer.price);

				if (couponValue) {
					totalValue -= couponValue;
				}
			}

			setTotalAmountWithInterest(totalValue);
		}
	}, [selectedPaymentMethod, selectedInstallment, itemsAmount]);

	useEffect(() => {
		if (
			(isBankSlip(fromPaymentMethod) && offer?.allow_bank_slip_installments) ||
			selectedPaymentMethod === PaymentMethods.CARD
		) {
			getInstallments(offer, itemsAmount);
		}
	}, [selectedPaymentMethod, itemsAmount, fromPaymentMethod, offer, couponValue]);

	useEffect(() => {
		if (!offer) return;
		applyCoupon(offer.code);
	}, [applyCoupon, upsellDiscount, offer]);

	useEffect(() => {
		const currencyCookie = parseCookies()['@ticto@checkout@currency'];
		api.defaults.headers['X-Tic-Currency'] = currency ?? currencyCookie ?? 'BRL';
	}, [currency]);

	return (
		<OneClickContext.Provider
			value={{
				useQuery,
				oneClickFormRef,
				isLoading,
				isSending,
				isCalculating,

				isModalOpen,
				setIsModalOpen,

				paymentError,
				setPaymentError,

				cards,
				selectedCard,
				setSelectedCard,

				error,

				resetErrors,

				fetch,
				track,
				pay,
				decline,

				fromTransaction,
				fromPaymentMethod,

				offer,
				setOffer,

				product,
				affiliates,
				bumps,

				installments,
				minPrice,
				selectedInstallment,
				changeInstallmentOption,

				totalAmountWithInterest,
				setTotalAmountWithInterest,

				items,
				setItems,
				itemsAmount,
				calculateItemsAmount,

				currency,
				country,

				couponValue,

				previousOrder,
				actualTransaction,
				isWaitingPixPayment,
				paymentResponse,
				redirect,

				handleAceptDiscount,
			}}
		>
			{children}
			{isConfirmModalVisible && (
				<ConfirmModal
					message={confirmModalContent.message}
					description={confirmModalContent.description}
					content={confirmModalContent.content}
					confirmButtonText={confirmModalContent.confirmButtonText}
					cancelButtonText={confirmModalContent.cancelButtonText}
					onConfirm={handleConfirm}
				/>
			)}
		</OneClickContext.Provider>
	);
};

export const useOneClick = () => {
	const context = useContext(OneClickContext);
	if (context === undefined) {
		throw new Error('useOneClick está fora de OneClickProvider.');
	}
	return context;
};
