import { useFlow } from '../../contexts/FlowContext';
import { getParameterByName } from '../../utils/urlParams';
import { TictoLoader } from '../TictoLoader';
import { Container } from './Container';

const Teste = ({ execute, reject, flowUUID }) => {
	const { step, isLoading, flow } = useFlow();

	if (flow) {
		return (
			<Container>
				<div className='box'>
					<h1>TESTES DE FLOW</h1>
					<h3 style={{ color: '#06d6a0' }}>Etapa do flow identificada</h3>
					{isLoading ? (
						<TictoLoader />
					) : (
						<b>
							Oferta: {step?.code ?? <span style={{ color: '#ef476f' }}>Erro ao encontrar oferta</span>}
						</b>
					)}

					<button
						type='button'
						style={{ background: '#06d6a0', border: '0', padding: '.5rem 1rem', color: 'white' }}
						className='ticto-upsell-button'
						onClick={() => execute()}
					>
						SIMULAR ACEITE DO UPSELL
					</button>
					<button
						type='button'
						style={{ background: '#ef476f', border: '0', padding: '.5rem 1rem', color: 'white' }}
						className='ticto-refuse-button'
						onClick={() => reject()}
					>
						SIMULAR RECUSA DO UPSELL
					</button>
				</div>
			</Container>
		);
	}

	if (!getParameterByName('hash')) {
		return (
			<Container>
				<div className='box'>
					<strong>Informe a HASH na url para testar o upsell.</strong>
					<p>Ex: ?hash=TOB12011JEC16NI </p>
				</div>
			</Container>
		);
	}

	return (
		<Container>
			<div className='box'>
				<h1>TESTES DE UPSELL</h1>
				<b>Insira um código de oferta</b>
				<input type='text' name='offer' id='dev_offer_id' />
				<button
					type='button'
					style={{ background: '#06d6a0', border: '0', padding: '.5rem 1rem', color: 'white' }}
					className='ticto-upsell-button'
					onClick={() => execute()}
				>
					SIMULAR ACEITE DO UPSELL
				</button>
				<button
					type='button'
					style={{ background: '#ef476f', border: '0', padding: '.5rem 1rem', color: 'white' }}
					className='ticto-refuse-button'
					onClick={() => reject()}
				>
					SIMULAR RECUSA DO UPSELL
				</button>
			</div>
		</Container>
	);
};

export default Teste;
